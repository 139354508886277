import React, { useCallback } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as Yup from 'yup'
import tw, { css } from 'twin.macro'
import { useAuth } from '../context/auth-provider'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { FormControl, InputError, input, label } from '../components/FormControls'
import Button from '../components/Button'

const schema = Yup.object({
  email: Yup.string().email('Invalid email format').required(),
  password: Yup.string().required(),
})

const IndexPage = () => {
  const { user, login } = useAuth()

  if (user) {
    navigate(`/app/dashboard`)
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmit = useCallback(
    async ({ email, password }) => {
      try {
        await login(email, password)
        navigate('/')
      } catch (error) {
        console.log('display errors here', error)
      }
    },
    [login]
  )

  return (
    <Layout tw="bg-gray-100">
      <SEO title="Home" />
      <div tw="flex h-full justify-center items-center">
        <div
          tw="bg-white w-full p-8 shadow-lg rounded-lg"
          css={css`
            max-width: 400px;
          `}
        >
          <h1 tw="block text-center font-bold text-purple text-lg border-b border-purple-lightest pb-4 mb-4">
            Please sign-in to continue:
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl tw="w-full">
              <label css={label} htmlFor="email">
                Email Address
              </label>
              <input ref={register} id="email" css={input} name="email" placeholder="me@umbrellafamily.com" />
              <InputError>{errors.email?.message}</InputError>
            </FormControl>
            <FormControl tw="w-full">
              <label css={label} htmlFor="password">
                Password
              </label>
              <input ref={register} id="password" css={input} name="password" type="password" />
              <InputError>{errors.password?.message}</InputError>
            </FormControl>

            <Button isPrimary type="submit">
              Login
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
